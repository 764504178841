import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../layouts"
import Seo from "../components/seo"

import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
  SectionDescription,
  SectionInnerWhite,
} from "../components/Section"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  HeroDescription,
} from "../components/Hero"
import ExpertSection from "../components/Expert"
import ColorOptionForm from "../components/ColorOptionForm"
import ColorBoards from "../components/ColorBoards"
import BreakpointUp from "../components/Media/BreakpointUp"

const ColorContainer = styled.div`
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
`
const ColorBoardsWarp = styled.div`
  max-width: 840px;
  width: 100%;
  margin: 0 auto 60px auto;
  text-align: center;
  ${BreakpointUp.sm`     
    text-align:left;
  `}
`
const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const ThumbFigure = styled.figure`
  position: relative;
  width: 100%;
  margin-bottom: 0;
  .gatsby-image-wrapper {
    width: 100%;
    min-height: 45vw;
  }
  &:before {
    font-family: "Barlow", sans-serif;
    content: attr(data-shadowtext);
    position: absolute;
    color: rgba(0, 0, 0, 0.04);
    font-weight: 900;
    right: 0;
    text-align: center;
    z-index: 1;
    font-size: 45px;
    line-height: 32px;
    bottom: calc(100% + 15px);
    @media (min-width: 1200px) {
      font-size: 90px;
      line-height: 74px;
      bottom: calc(100% + 30px);
    }
  }
`

const FormContainer = styled.div`
  .form-action {
    text-align: center;
    margin: 30px 0 0;
    ${BreakpointUp.md`        
        position: absolute;
        left: 0;
        right: 0;            
        top: calc(100% - 22px);        
        margin: 0;
    `}
    ${BreakpointUp.lg`             
        top: calc(100% - 26px);
    `}
  }
  textarea.form-control {
    height: 82px;
    ${BreakpointUp.lg`
      height: 150px;
    `}
    ${BreakpointUp.xl`
      height: 200px;
    `}
    ${BreakpointUp.xxl`
      height: 300px;
    `}
  }
`
const ColorOption = ({ location, data }) => {
  const pageData = data.contentfulColorOptionsPage
  const [color, setColor] = useState(pageData.colors[0].name)
  const [colorImage, setColorImage] = useState(
    pageData.colors[0].sampleImage.gatsbyImageData
  )
  useEffect(() => {
    const currentColor = pageData.colors.filter(item => item.name === color)
    setColorImage(currentColor[0].sampleImage.gatsbyImageData)
  }, [color, setColor])

  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section xpt="114px" mpt="94px" pt="64px" pb="0" bgColor="transparent">
        <Container maxWidth="100%" pl="3%" pr="3%">
          <HeroContainer>
            <Hero>
              <Background>
                <GatsbyImage
                  image={getImage(pageData.heroImage.gatsbyImageData)}
                  alt="hero-bg"
                />
              </Background>
              <HeroCaption>
                <BreadCrumb className="white" position="static" translate="0">
                  <Link to="/">Home</Link>
                  <span>{pageData.heroTitle}</span>
                </BreadCrumb>
                <HeroTitle>{pageData.heroTitle}</HeroTitle>
                <HeroDescription maxWidth="890px">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                </HeroDescription>
              </HeroCaption>
            </Hero>
          </HeroContainer>
        </Container>
      </Section>

      <Section pt="0" xpb="30px" mpb="20px" pb="15px" bgColor="transparent">
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite>
            <ColorContainer>
              <ColorBoardsWarp>
                <ColorBoards colors={pageData.colors} setColor={setColor} />
              </ColorBoardsWarp>
              <Thumbnail className="thumbnail">
                <ThumbFigure className="thumb-img" data-shadowtext={color}>
                  <GatsbyImage image={getImage(colorImage)} alt={color} />
                </ThumbFigure>
              </Thumbnail>
            </ColorContainer>
          </SectionInnerWhite>
        </Container>
      </Section>

      <Section
        xpt="30px"
        mpt="20px"
        pt="15px"
        xpb="90px"
        mpb="70px"
        pb="50px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite>
            <SectionTitle>REQUEST COLOR SAMPLES</SectionTitle>
            <SectionDescription maxWidth="1140px">
              <p>Get the type of color for your dream metal building that best suits your needs.</p>
            </SectionDescription>
            <FormContainer>
              <ColorOptionForm location={location} />
            </FormContainer>
          </SectionInnerWhite>
        </Container>
      </Section>
      <ExpertSection />
    </Layout>
  )
}

export default ColorOption

export const query = graphql`
  query ColorOptionQuery {
    contentfulColorOptionsPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(quality: 100)
      }
      colors {
        name
        colorCode
        sampleImage {
          gatsbyImageData(quality: 100)
        }
      }
      formSectionTitle
      formSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
