import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'

export const SectionExpert = styled.div`
  background-color:#033366;
  overflow:hidden;
  border-radius:6px;
  text-align:center;
  position: relative;
`
export const SectionToolbar = styled.div`
  .btn{
    max-width:340px;
    width:100%;
    ${BreakpointUp.lg`
      font-size:20px;
    `}
  }
`
export const ExpertBackground = styled.div`
  width:100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.3;
  .gatsby-image-wrapper{
    height: 100%;
    display:block;
  }
`
export const SectionExpertContent = styled.div`
    position: relative;
    z-index:1;
    padding:30px 4.166%;
    ${BreakpointUp.xl`
        padding:60px 4.166%;
    `}
    ${BreakpointUp.xxl`
        padding:90px 8.333%;
    `}
`