import React from "react"
import styled from "styled-components"
import BreakpointUp from "../../components/Media/BreakpointUp"

export const ListColorBorad = styled.div`
  list-style: none;
  margin: 0 -5px;
  padding: 0;
`
export const ColorItem = styled.div`
  display: inline-block;
  padding: 0 5px;
  margin-bottom: 15px;
`
export const ColorCode = styled.span`
  background: ${props => props.bg};
  display: block;
  margin-bottom: 2px;
  border: 4px solid #f2f4f9;
  width: 80px;
  height: 30px;
  ${BreakpointUp.sm`     
		width: 100px;		
		height: 34px;
    `}
`
export const ColorName = styled.span`
  display: block;
  color: #666;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  ${BreakpointUp.sm`     
		font-size:14px;
		line-height:24px;
	`}
`

const ColorBoard = ({ colors, setColor }) => {
  return (
    <ListColorBorad>
      {colors &&
        colors.map(item => {
          return (
            <ColorItem key={item.name} onClick={() => setColor(item.name)}>
              <ColorCode bg={item.colorCode}></ColorCode>
              <ColorName>{item.name}</ColorName>
            </ColorItem>
          )
        })}
    </ListColorBorad>
  )
}

export default ColorBoard
